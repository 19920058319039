<template>
  <Teleport to="body">
    <AgoraVideoCall :instantCallContact :minimizeVideo :openAgoraVideo />
  </Teleport>
  <!--<Messages />-->
  <div class="h-full">
    <PSideBar v-model="sidebarOpen" :hideSideBarForShow :items="navigation" />
    <div :class="hideSideBarForShow ? '' : 'md:pl-64'" class="flex flex-1 h-full flex-col">
      <div class="sticky top-0 flex h-16 flex-shrink-0 bg-slate-800 shadow z-20">
        <button
          :class="hideSideBarForShow ? '' : 'md:hidden'"
          class="border-r border-slate-900 px-4 text-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
          type="button"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon aria-hidden="true" class="h-6 w-6" />
        </button>
        <div class="flex flex-1 px-4 justify-between">
          <div class="flex">
            <div v-if="hideSideBarForShow" class="flex items-center h-16 flex-shrink-0 bg-slate-800">
              <PLogo class="h-10 w-10" fill1="fill-primary-700" fill2="fill-primary-600" fill3="fill-primary-700" />
              <div class="flex flex-col ml-4">
                <p class="text-lg font-semibold text-white leading-none">Mid-States</p>
                <p class="text-xs mt-0.5 text-slate-200/50 leading-none">{{ portalTitle }}</p>
              </div>
            </div>
            <div :class="[hideSideBarForShow ? 'pl-[6.53rem]' : '']">
              <form action="#" class="flex h-full w-full md:ml-0" method="GET">
                <label class="sr-only" for="search-field">Search</label>
                <div class="relative text-slate-400 focus-within:text-slate-600 hover:text-slate-500">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <MagnifyingGlassIcon aria-hidden="true" class="h-5 w-5" />
                  </div>
                  <button
                    class="block h-full border-transparent py-2 pl-7 pr-3 font-medium placeholder-slate-500 focus:border-transparent focus:placeholder-slate-400 focus:outline-none focus:ring-0 sm:text-sm"
                    type="button"
                    @click="showUserSearch = true"
                  >
                    Search
                    <span
                      class="ml-4 hidden rounded-md border-2 border-solid border-slate-600 p-1 text-xs font-normal hover:border-slate-500 md:inline-block"
                      >Ctrl K</span
                    >
                  </button>
                  <PSearchCommandPalette v-model="showUserSearch" />
                </div>
              </form>
            </div>
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            <!-- <button v-whats-new-tour class="mr-3">
              <span
                class="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30"
                >New Features</span
              >
            </button> -->
            <!-- <button
              type="button"
              class="rounded-full bg-white p-1 text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->

            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  class="flex max-w-xs items-center rounded-full bg-slate-800 text-sm focus:outline-none lg:rounded-md lg:p-2 lg:hover:bg-slate-700/75"
                  dusk="user-menu"
                >
                  <MsdAvatar :size="'size-8'" :textSize="'text-sm'" :user="$page.props.auth.user" />
                  <span class="ml-3 hidden text-sm font-medium text-white lg:block"
                    ><span class="sr-only">Open user menu for </span>{{ $page.props.auth.user.name }}</span
                  >
                  <ChevronDownIcon
                    aria-hidden="true"
                    class="ml-1 hidden h-5 w-5 flex-shrink-0 text-slate-500 lg:block"
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 mt-2 w-60 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div class="truncate px-3.5 py-3 border-b border-slate-100">
                    <p class="block text-xs text-slate-500">Signed in as</p>
                    <p class="truncate text-sm font-medium">{{ $page.props.auth.user.email_address }}</p>
                  </div>
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active, close }" as="div">
                    <form v-if="item.route == 'logout'" class="border-t border-slate-100" @submit.prevent="logout">
                      <button
                        v-if="!user.is_impersonated"
                        :class="[
                          active ? 'bg-slate-100' : '',
                          'w-full text-left block px-3.5 py-2 text-sm text-slate-800',
                        ]"
                        dusk="logout-button"
                        type="submit"
                      >
                        Sign out
                      </button>
                      <Link
                        v-else
                        :class="[active ? 'bg-slate-100' : '', 'block px-3.5 py-4 text-sm text-slate-800']"
                        :href="route('user.leave-impersonation')"
                        @click="close"
                      >
                        Leave Impersonation
                      </Link>
                    </form>
                    <Link
                      v-else
                      :class="[active ? 'bg-slate-100' : '', 'block px-3.5 py-2 text-sm text-slate-800']"
                      :href="route(item.route)"
                      @click="close"
                    >
                      {{ item.name }}
                    </Link>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <div
        :class="{
          'overflow-hidden':
            ($page.component.startsWith('Events/Event') &&
              !$page.component.startsWith('Events/Event/Registration') &&
              !$page.component.startsWith('Events/Event/Summit')) ||
            openAgoraVideo,
        }"
        class="h-full relative"
      >
        <div v-if="message" class="border-yellow-400 bg-yellow-50 p-4">
          <div class="flex justify-center">
            <div class="flex-shrink-0">
              <ExclamationTriangleIcon aria-hidden="true" class="h-5 w-5 text-yellow-400" />
            </div>
            <div class="ml-3">
              <p class="text-sm text-yellow-700" v-text="message"></p>
            </div>
          </div>
        </div>
        <slot />
        <PNotification></PNotification>
        <PConfirmation></PConfirmation>
      </div>
    </div>
  </div>
</template>

<script setup>
import MsdAvatar from "@Components/MsdAvatar.vue";
import { useStr } from "@Composables";
import {
  PConfirmation,
  PLogo,
  PNotification,
  PSearchCommandPalette,
  PSideBar,
  useConfirmation,
  useNotification,
  useUserStore,
} from "@Library";
import { useOnlineUserStore } from "@Stores/onlineUsersStore";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ExclamationTriangleIcon, MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import {
  AcademicCapIcon,
  Bars3Icon,
  BeakerIcon,
  BookOpenIcon,
  BuildingOffice2Icon,
  CalculatorIcon,
  CalendarIcon,
  ChartBarIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  HomeIcon,
  IdentificationIcon,
  MapPinIcon,
  MegaphoneIcon,
  PresentationChartLineIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  SquaresPlusIcon,
  TruckIcon,
  UserGroupIcon,
} from "@heroicons/vue/24/outline";
import { Link, router, usePage } from "@inertiajs/vue3";
import { computed, provide, reactive, ref, watch } from "vue";

import AgoraVideoCall from "@Components/AgoraVideoCall.vue";
// import Messages from "@Components/Messages.vue";
const { confirm } = useConfirmation();

// Start Chat
const state = reactive({
  isMessagesOpen: false,
  toggleMessages: (value) => {
    state.isMessagesOpen = value;
  },
});
const hideSideBarForShow = ref(false);

const { whisperUserBusy } = useOnlineUserStore();

router.on("navigate", (event) => {
  hideSideBarForShow.value =
    !event.detail.page.component.startsWith("Events/Event/Show/Lobby") &&
    !event.detail.page.component.startsWith("Events/Event/ShowOnlyBuys") &&
    !event.detail.page.component.startsWith("Events/Event/ShowSpecialsApproval") &&
    event.detail.page.component.startsWith("Events/Event/Show");
});

provide("layoutState", state);
// End Chat

const openAgoraVideo = ref(false);
const minimizeVideo = ref(false);

const page = usePage();

const { notify } = useNotification();
const user = computed(() => page.props.auth.user);
const message = computed(() => page.props.flash.message);
const growl = computed(() => page.props.flash.growl);
const liveEvent = computed(() => page.props?.live_event);
const isManningBooth = computed(() => page.props?.is_manning_booth);
const noCompany = computed(() => [null, undefined, ""].includes(user.value?.company_id));

const instantCallContact = ref(null);
const blockCalls = ref(false);

window.addEventListener("beforeunload", () => {
  // TODO: Not really happy with this. We can look at it after the show.
  blockCalls.value = true;
  instantCallContact.value = null;
});

const toggleAgoraVideo = (contact) => {
  if (blockCalls.value) return;
  if (contact) {
    // If there is a contact, that means a member is calling a booth contact
    instantCallContact.value = contact;
    openAgoraVideo.value = !openAgoraVideo.value;
  } else {
    openAgoraVideo.value = !openAgoraVideo.value;

    if (!openAgoraVideo.value) {
      instantCallContact.value = null;
    }
  }
};
const toggleMinimize = () => {
  minimizeVideo.value = !minimizeVideo.value;
};
const resetMinimize = () => {
  minimizeVideo.value = false;
};

provide("toggleAgoraVideo", toggleAgoraVideo);
provide("toggleMinimize", toggleMinimize);
provide("resetMinimize", resetMinimize);

// Global growl notification
watch(growl, (value) => {
  if (value) {
    notify(value);
  }
});

useUserStore().set(user.value);

const { capitalize } = useStr();

const portalTitle = computed(() => {
  return user.value.type === "vendor" ? "Supplier Portal" : `${capitalize(user.value.type)} Portal`;
});

let navigations = {
  member: [
    { name: "Dashboard", route: "dashboard.index", icon: HomeIcon, component: "Member/Dashboard" },
    { name: '"Blue Book"', route: "blue-book-platform.index", icon: BookOpenIcon },
    {
      name: "Calendar of Events",
      route: "upcoming-events.index",
      component: "UpcomingEvents/Index",
      icon: CalendarIcon,
    },
    {
      name: "Accounting",
      icon: CalculatorIcon,
      children: [
        { name: "Invoices", route: "invoices.index", component: "Member/Invoices" },
        {
          name: "Invoice Research",
          route: "invoice-research.index",
          component: "Member/InvoiceResearches",
          disabled: noCompany.value,
        },
        { name: "Claims", route: "claims.index", component: "Claims/Index" },
      ],
    },
    {
      name: "Warehouse Programs Information",
      icon: TruckIcon,
      children: [
        { name: "Catalog", route: "warehouse.catalog.index", component: "Warehouse/Catalog/Index" },
        // { name: "Reports", route: "warehouse.reports.index", component: "Warehouse/Reports/Index" },
        { name: "Private Brands", route: "warehouse.private-brands.index", component: "Warehouse/PrivateBrands/Index" },
        {
          name: "Marketing Assets",
          route: "warehouse.marketing-assets.index",
          component: "Warehouse/MarketingAssets/Index",
        },
        { name: "Item Master", route: "warehouse.item-master.index", component: "Warehouse/ItemMaster/Index" },
        // Removed for now
        // { name: "Forecasting", route: "forecasting.index", component: "Forecasting/Index" },
        { name: "Defective Form", route: "warehouse.defective-form.index", download: true },
        // {
        //   name: "Stage Gate Projects",
        //   route: "stage-gate-projects.index",
        //   component: "StageGateProjects",
        //   class: "nav-stage-gate-projects",
        // }
      ],
    },
    {
      name: "Contacts",
      icon: UserGroupIcon,
      children: [
        {
          name: "MSD Board Members",
          route: "contacts.msd-board-members.index",
          component: "Contacts/MsdBoardMembers",
          disabled: noCompany.value,
        },
        {
          name: "MSD Contacts",
          route: "contacts.msd-contacts.index",
          component: "Contacts/MsdContacts",
          disabled: noCompany.value,
        },
        {
          name: "Member Companies",
          route: "contacts.member-companies.index",
          component: "Contacts/MemberCompanies",
          disabled: noCompany.value,
        },
        {
          name: "Suppliers",
          route: "contacts.suppliers.index",
          component: "Contacts/Suppliers",
          disabled: noCompany.value,
        },
        {
          name: "Rep Groups",
          route: "contacts.reps.index",
          component: "Contacts/Reps",
          disabled: noCompany.value,
        },
        {
          name: "My Company Profile/Contacts",
          route: "company-profile.index",
          component: "CompanyProfile/Index",
          disabled: noCompany.value,
        },
      ],
    },
    { name: "Shows", route: "events.index", icon: CurrencyDollarIcon, component: "Events" },
    // { name: "Contacts", route: "contacts.index", icon: UserGroupIcon, component: "Contacts" },
    { name: "Committees", route: "committees.index", icon: RectangleStackIcon, component: "Committees/Index" },
    // { name: "Messages", action: () => state.toggleMessages(true), icon: ChatBubbleBottomCenterTextIcon },
    // {
    //   name: "Events",
    //   icon: CalendarIcon,
    //   children: [
    //     { name: "Event Calendar", route: "upcoming-events.index", component: "UpcomingEvents/Index" },
    //     // { name: "Event Registration", route: "events.registration.index", component: "Events/Registration/Index" },
    //     // { name: "Virtual Shows - Active", route: "events.index", component: "Events/Index" },
    //     // {
    //     //   name: "Virtual Shows - Archived",
    //     //   route: "events.archived.index",
    //     //   component: "Events/Archived/Index",
    //     // },

    //     { name: "Virtual Shows", route: "events.index", component: "Events" },
    //   ],
    // },
    { name: "Member Map", route: "locations.index", icon: MapPinIcon, component: "Locations/Index" },
    // { name: "Reports", route: "reports.index", icon: ChartBarIcon, component: "Reports/Index" },
    {
      name: "Central Services",
      defaultOpen: true,
      icon: SquaresPlusIcon,
      children: [
        { name: "3E", href: "https://3eco.com", target: "_blank", external: true },
        { name: "Weather Trends", href: "https://weathertrends360.com", target: "_blank", external: true },
        { name: "National Shooting Sports", href: "https://www.nssf.org", target: "_blank", external: true },
      ],
    },
  ],
  admin: [
    { name: "Dashboard", route: "dashboard.index", icon: HomeIcon, component: "Admin/Dashboard" },
    {
      name: "Blue Book Platform",
      route: "blue-book-platform.index",
      icon: BookOpenIcon,
      component: "BlueBookPlatform/BlueBookProgram/Index",
    },
    {
      name: "Contacts",
      icon: UserGroupIcon,
      children: [
        { name: "MSD Board Members", route: "contacts.msd-board-members.index", component: "Contacts/MsdBoardMembers" },
        { name: "MSD Contacts", route: "contacts.msd-contacts.index", component: "Contacts/MsdContacts" },
        { name: "Member Companies", route: "contacts.member-companies.index", component: "Contacts/MemberCompanies" },
        { name: "Suppliers", route: "contacts.suppliers.index", component: "Contacts/Suppliers" },
        { name: "Rep Groups", route: "contacts.reps.index", component: "Contacts/Reps" },
        {
          name: "My Company Contacts",
          route: "contacts.my-company-contacts.index",
          component: "Contacts/MyCompanyContacts",
        },
      ],
    },
    // { name: "Contacts", route: "contacts.index", icon: UserGroupIcon, component: "Contacts" },
    { name: "Committees", route: "committees.index", icon: RectangleStackIcon, component: "Committees/Index" },
    {
      name: "Warehouse Programs",
      icon: TruckIcon,
      children: [
        // { name: "Reports", route: "analytics.index", component: "Admin/Analytic/Index" },
        {
          name: "Marketing Assets",
          route: "warehouse.marketing-assets.index",
          component: "Warehouse/MarketingAssets/Index",
        },
      ],
    },
    // {
    //   name: "Events Calendar",
    //   route: "events.calendar.index",
    //   icon: CalendarIcon,
    //   component: "Events/Calendar/Index",
    // },
    {
      name: "InLook",
      route: "communications.index",
      icon: MegaphoneIcon,
      component: "InLook",
      // children: [
      //   // { name: "Reports", route: "analytics.index", component: "Admin/Analytic/Index" },
      //   // {
      //   //   name: "List Communications",
      //   //   route: "communications.index",
      //   //   component: "Admin/Communication",
      //   // },
      //   {
      //     name: "Broadcasts",
      //     route: "communications.index",
      //     component: "Broadcasts/",
      //   },
      //   {
      //     name: "Manage Groups",
      //     route: "manage-groups.index",
      //     component: "ManageGroups/",
      //   },
      //   {
      //     name: "External Users",
      //     route: "external-users.index",
      //     component: "ExternalUsers/",
      //   },
      // ],
    },
    {
      name: "Projects",
      route: "projects.index",
      icon: RectangleGroupIcon,
      component: "Projects",
    },
    {
      name: "Stage Gate Projects",
      route: "stage-gate-projects.index",
      icon: BeakerIcon,
      component: "StageGateProjects",
      class: "nav-stage-gate-projects",
    },
    {
      name: "Employee Center",
      icon: IdentificationIcon,
      children: [
        {
          groups: [
            {
              name: "Shortcuts",
              children: [
                {
                  name: "Employee Store",
                  href: "https://midstates.rtgstores.com/mid-states-distributing",
                  target: "_blank",
                  external: true,
                },
                {
                  name: "Dynamics 365 Prod",
                  href: "https://msdist.operations.dynamics.com",
                  target: "_blank",
                  external: true,
                },
                {
                  name: "Dynamics 365 Dev",
                  href: "https://mid-uat.sandbox.operations.dynamics.com",
                  target: "_blank",
                  external: true,
                },
                {
                  name: "Dynamics CRM",
                  href: "https://midstates.crm.dynamics.com",
                  target: "_blank",
                  external: true,
                },
                {
                  name: "Dynamics CRM Dev",
                  href: "https://midstatessandbox.crm.dynamics.com",
                  target: "_blank",
                  external: true,
                },
                {
                  name: "Paycom",
                  href: "https://www.paycomonline.net/v4/ee/web.php/app/login",
                  target: "_blank",
                  external: true,
                },
              ],
            },
            {
              name: "Support",
              children: [
                { name: "IT Support", href: "mailto:itsupport@msdist.com", email: true },
                { name: "Facilities Support", href: "mailto:facilities@msdist.com", email: true },
              ],
            },
            {
              name: "HR",
              children: [{ name: "Files", route: "employee-center.files.index", component: "EmployeeCenter/files" }],
            },
          ],
        },
      ],
    },
    {
      name: "Forecasting",
      route: "forecasting.index",
      icon: PresentationChartLineIcon,
      component: "Forecasting/Index",
    },
    {
      name: "Calendar of Events",
      route: "upcoming-events.index",
      component: "UpcomingEvents/Index",
      icon: CalendarIcon,
    },
    { name: "Shows", route: "events.index", icon: CurrencyDollarIcon, component: "Events" },
    { name: "LMS Training", route: "lms.index", icon: AcademicCapIcon, component: "Lms/Index" },
    { name: "Member Map", route: "locations.index", icon: MapPinIcon, component: "Locations/Index" },
    {
      name: "Company Profiles",
      icon: BuildingOffice2Icon,
      children: [
        { name: "Manage Profiles", route: "company-profiles.index", component: "CompanyProfiles/Index" },
        { name: "Manage Questions", route: "company-profiles.questions.index", component: "CompanyProfiles/Questions" },
        {
          name: "Manage Job Responsibilities",
          route: "job-responsibilities.index",
          component: "JobResponsibilities/Index",
        },
      ],
    },
    { name: "Reports", route: "reports.index", icon: ChartBarIcon, component: "Reports/Index" },
    {
      name: "Central Services",
      icon: SquaresPlusIcon,
      defaultOpen: true,
      children: [
        { name: "3E", href: "https://3eco.com", target: "_blank", external: true },
        { name: "Weather Trends", href: "https://weathertrends360.com", target: "_blank", external: true },
        { name: "National Shooting Sports", href: "https://www.nssf.org", target: "_blank", external: true },
      ],
    },
  ],
  vendor: [
    { name: "Dashboard", route: "dashboard.index", icon: HomeIcon, component: "Vendor/Dashboard" },
    {
      name: "Program Maintenance",
      subName: "(Blue Book)",
      route: "blue-book-platform.supplier.show",
      icon: BookOpenIcon,
      params: { supplier: user.value.company_id },
    },
    {
      name: "Calendar of Events",
      route: "upcoming-events.index",
      component: "UpcomingEvents/Index",
      icon: CalendarIcon,
    },
    // ! Suppliers do not get access to the Blue Book Platform
    {
      name: "Contacts",
      icon: UserGroupIcon,
      children: [
        {
          name: "MSD Board Members",
          route: "contacts.msd-board-members.index",
          component: "Contacts/MsdBoardMembers",
          disabled: noCompany.value,
        },
        {
          name: "MSD Contacts",
          route: "contacts.msd-contacts.index",
          component: "Contacts/MsdContacts",
          disabled: noCompany.value,
        },
        {
          name: "Member Companies",
          route: "contacts.member-companies.index",
          component: "Contacts/MemberCompanies",
          disabled: noCompany.value,
        },
        // { name: "Suppliers", route: "contacts.suppliers.index", component: "Contacts/Suppliers" },
        // { name: "Rep Groups", route: "contacts.reps.index", component: "Contacts/Reps" },
        {
          name: "My Company Profile/Contacts",
          route: "company-profile.index",
          component: "CompanyProfile.Index",

          disabled: noCompany.value,
        },
      ],
    },
    { name: "Committees", route: "committees.index", icon: RectangleStackIcon, component: "Committees/Index" },
    // { name: "Warehouse", route: "warehouse.index", icon: TruckIcon, component: "Warehouse/Index" },
    // {
    //   name: "Events Calendar",
    //   route: "events.calendar.index",
    //   icon: CalendarIcon,
    //   component: "Events/Calendar/Index",
    // },
    // { name: "Analytics", route: "vendor.analytics.index", icon: ChartBarIcon, component: "Vendor/Analytics" },
    // { name: "Files", route: "vendor.files.index", icon: FolderIcon, component: "Vendor/Files" },
    // { name: "Videos", route: "vendor.videos.index", icon: VideoCameraIcon, component: "Vendor/Videos" },
    // { name: "Messages", action: () => state.toggleMessages(true), icon: ChatBubbleBottomCenterTextIcon },
    { name: "Shows", route: "events.index", icon: CurrencyDollarIcon, component: "Events" },
    // {
    //   name: "Events",
    //   icon: CalendarIcon,
    //   children: [
    //     { name: "Event Calendar", route: "upcoming-events.index", component: "UpcomingEvents/Index" },
    //     // {
    //     //   name: "Events Calendar",
    //     //   route: "events.calendar.index",
    //     //   component: "Events/Calendar/Index",
    //     // },
    //     { name: "Virtual Shows", route: "events.index", component: "Events" },
    //     // {
    //     //   name: "Virtual Shows - Archived",
    //     //   route: "events.archived.index",
    //     //   component: "Events/Archived/Index",
    //     // },
    //   ],
    // },
    { name: "Reports", route: "reports.index", icon: ChartBarIcon, component: "Reports/Index" },
  ],
  rep: [
    { name: "Dashboard", route: "dashboard.index", icon: HomeIcon, component: "Rep/Dashboard" },
    {
      name: "Program Maintenance",
      subName: "(Blue Book)",
      route: "program-maintenance.index",
      icon: BookOpenIcon,
      component: "Rep/ProgramMaintenance",
    },
    {
      name: "Calendar of Events",
      route: "upcoming-events.index",
      component: "UpcomingEvents/Index",
      icon: CalendarIcon,
    },
    // ! Reps do not get access to the Blue Book Platform
    {
      name: "Contacts",
      icon: UserGroupIcon,
      children: [
        {
          name: "MSD Board Members",
          route: "contacts.msd-board-members.index",
          component: "Contacts/MsdBoardMembers",
          disabled: noCompany.value,
        },
        {
          name: "MSD Contacts",
          route: "contacts.msd-contacts.index",
          component: "Contacts/MsdContacts",
          disabled: noCompany.value,
        },
        {
          name: "Member Companies",
          route: "contacts.member-companies.index",
          component: "Contacts/MemberCompanies",
          disabled: noCompany.value,
        },
        {
          name: "Suppliers",
          route: "contacts.suppliers.index",
          component: "Contacts/Suppliers",
          disabled: noCompany.value,
        },
        // { name: "Rep Groups", route: "contacts.reps.index", component: "Contacts/Reps" },
        {
          name: "My Company Profile/Contacts",
          route: "company-profile.index",
          component: "CompanyProfile/Index",
          disabled: noCompany.value,
        },
      ],
    },
    { name: "Committees", route: "committees.index", icon: RectangleStackIcon, component: "Committees/Index" },
    // { name: "Contacts", route: "contacts.index", icon: UsersIcon, component: "Contacts/Index" },
    // { name: "Live Events", route: "events.index", icon: CalendarIcon, component: "Events/Index" },
    { name: "Shows", route: "events.index", icon: CurrencyDollarIcon, component: "Events" },
    // {
    //   name: "Events",
    //   icon: CalendarIcon,
    //   children: [
    //     { name: "Event Calendar", route: "upcoming-events.index", component: "UpcomingEvents/Index" },
    //     // {
    //     //   name: "Events Calendar",
    //     //   route: "events.calendar.index",
    //     //   component: "Events/Calendar/Index",
    //     // },
    //     { name: "Virtual Shows", route: "events.index", component: "Events/Index" },
    //     // {
    //     //   name: "Virtual Shows - Archived",
    //     //   route: "events.archived.index",
    //     //   component: "Events/Archived/Index",
    //     // },
    //   ],
    // },
    { name: "Reports", route: "reports.index", icon: ChartBarIcon, component: "Reports/Index" },
  ],
};

const navigation = computed(() => {
  if (user.value.type === "member" && !user.value?.is_accountant) {
    return navigations.member.filter((item) => item.name !== "Accounting");
  }

  return user.value?.type ? navigations[user.value.type] : {};
});

const userNavigation = [
  { name: "Account Settings", route: `account-settings.index` },
  { name: "Sign out", route: "logout" },
];

if (user.value.type !== "admin") {
  userNavigation.splice(1, 0, { name: "My Company Profile", route: `company-profile.index` });
}

if (page.props.orderBuilderTool) {
  navigations.member
    .find((nav) => nav.name === "Warehouse Programs Information")
    .children.push({
      name: "Order Builder Tool",
      href: page.props.orderBuilderTool,
      target: "_blank",
      external: true,
    });
}

const sidebarOpen = ref(false);
const showUserSearch = ref(false);

const logout = () => {
  router.post(route("logout"));
};

Echo.join("onlineUsers").here(() => {
  if ((user.value.type === "vendor" || user.value.type === "rep") && isManningBooth.value) {
    confirm({
      title: "Call Notification",
      message: `<b>${liveEvent.value.name} is LIVE!</b>Would you like to receive calls at this time? <br /><br /> Reminder: You <b>cannot</b> share your login with someone else during the show. You will not be able to receive calls reliably if you do.`,
      okLabel: `Yes, I'm Ready`,
      cancelLabel: "Not Now",
      onCancel: () => {
        whisperUserBusy(user.value.id);
      },
    });
  }
});
</script>
