import moment from "moment-timezone";

/**
 * @return  {Object}
 */
export default function useDateFormatter() {
  /**
   * Default date format throughout the application
   *
   * @param {string} date
   * @param {boolean=} time
   * @param {string=} tz
   *
   * @return {(string|undefined)}
   */
  const formatDate = (date, time, tz) => {
    if (!date || date.indexOf("1900") > -1) {
      return undefined;
    }

    const format = time ? "MMM D, YYYY hh:mm A z" : "MMM D, YYYY";

    return tz ? moment(String(date)).tz(tz).format(format) : moment(String(date)).format(format);
  };

  const formatInputDate = (date, time = false) => {
    if (!date || date.indexOf("1900") > -1) {
      return "";
    }

    const format = time ? "MM/DD/YYYY, hh:mm A z" : "YYYY-MM-DD";
    return moment(String(date)).format(format);
  };

  const formatDateTimeWithTimezone = (date, time = false) => {
    if (!date || date.indexOf("1900") > -1) {
      return undefined;
    }

    const format = time ? "MMM D, YYYY hh:mm A z" : "MMM D, YYYY";
    return moment(String(date)).format(format);
  };

  return {
    formatDate,
    formatInputDate,
    formatDateTimeWithTimezone,
  };
}
