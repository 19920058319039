<script setup>
import { PPage } from "@/Library";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ArrowLeftIcon } from "@heroicons/vue/16/solid";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import { Head, Link, usePage } from "@inertiajs/vue3";
import { computed, ref, watch } from "vue";
import ITSupportBanner from "@/Components/ITSupportBanner.vue";

const props = computed(() => usePage().props);

const navigation = ref([
  {
    name: "Getting Started",
    route: "events.event.registration.step-one.get-started.index",
    component: "Events/Event/Registration/Steps/One/GetStarted",
    enabled: true,
    clickable: true,
  },
  {
    name: "Booth Selection",
    route: "events.event.registration.step-one.booth-selection.index",
    component: "Events/Event/Registration/Steps/One/BoothSelection",
    enabled: true,
    clickable: true,
  },
  {
    name: "Booth Placement",
    route: "events.event.registration.step-one.booth-placement.index",
    component: "Events/Event/Registration/Steps/One/BoothPlacement",
    enabled: true,
    clickable: props.value.registration?.booths?.length > 0,
  },
  {
    name: "Marketing Opportunities",
    route: "events.event.registration.step-one.booth-placement.index",
    component: "Events/Event/Registration/Steps/One/BoothPlacement",
    enabled: true,
    clickable: false,
    children: props.value.sponsorship_groups.map((group) => {
      return {
        id: group.id,
        name: group.name,
        route: "events.event.registration.step-one.opportunities.show",
        component: "Events/Event/Registration/Steps/One/MarketingOpportunities/Opportunity/Index",
        clickable: props.value.registration?.completed_at !== null,
      };
    }),
  },
  {
    name: "Checkout",
    route: "events.event.registration.step-one.review.index",
    component: "Events/Event/Registration/Steps/One/Review",
    enabled: true,
    clickable: props.value.registration?.payment_amount !== null,
  },
  {
    name: "View Invoice",
    route: "events.event.registration.step-one.invoice.index",
    component: "Events/Event/Registration/Steps/One/Invoice",
    enabled: true,
    clickable: props.value.registration?.payment_amount !== null,
  },
]);

if (props.value.event.event_type.slug == "apparel-footwear") {
  navigation.value = navigation.value.filter((item) => item.name !== "Booth Selection"); // Doing it this way instead of index in case the order changes
}

watch(
  () => props.value.sponsorship_groups,
  (groups) => {
    const marketingIndex = navigation.value.findIndex((item) => item.name === "Marketing Opportunities");

    navigation.value[marketingIndex].enabled = groups.length > 0;
    navigation.value[marketingIndex].children = groups.map((group, index) => {
      return {
        id: group.id,
        name: group.name,
        route: "events.event.registration.step-one.opportunities.show",
        component: "Events/Event/Registration/Steps/One/MarketingOpportunities/Opportunity/Index",
        clickable: props.value.registration?.completed_at !== null,
      };
    });
  }
);

// watch for changes in the registration.booths object to update the navigation
watch(
  () => props.value.registration?.booths,
  (booths) => {
    const boothSelectionIndex = navigation.value.findIndex((item) => item.name === "Booth Placement");

    if (booths !== undefined) {
      navigation.value[boothSelectionIndex].clickable = booths.length > 0;
    } else {
      navigation.value[boothSelectionIndex].clickable = false;
    }
  }
);

const isActive = (item) => {
  return route().params.sponsorship === item.id.toString();
};
</script>

<template>
  <Head title="Registration" />
  <ITSupportBanner />
  <PPage header-class="py-8 scroll-mt-8">
    <div class="odd:bg-inherit">
      <div class="flex">
        <div class="flex w-64 flex-col shrink-0">
          <div class="flex grow flex-col gap-y-5">
            <nav class="flex flex-1 flex-col">
              <ul class="flex flex-1 flex-col gap-y-7" role="list">
                <li>
                  <ul class="space-y-1" role="list">
                    <li>
                      <div class="mb-2 text-sm leading-7 flex">
                        <Link
                          :href="route('events.event.registration.index', [$page.props.event, $page.props.vendor])"
                          class="flex items-center space-x-2 group"
                        >
                          <span
                            class="rounded-full bg-slate-500 p-1 text-white shadow-sm group-hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                          >
                            <ArrowLeftIcon aria-hidden="true" class="h-3 w-3" />
                          </span>
                          <span class="text-slate-500 group-hover:text-slate-700">Back to registration</span>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div class="flex items-center h-9 space-x-2 mb-5">
                        <h3 class="flex items-center space-x-3 font-semibold leading-7 sm:truncate capitalize">
                          Step 1
                        </h3>
                        <span>-</span>
                        <p class="text-slate-600 font-medium">Show Registration</p>
                      </div>
                    </li>
                    <template v-for="(item, index) in navigation" :key="item.name">
                      <li v-if="item.enabled">
                        <component
                          :is="!item.clickable ? 'div' : Link"
                          v-if="!item.children"
                          :class="[
                            $page.component.startsWith(item.component)
                              ? 'bg-slate-200/60 text-slate-900'
                              : 'text-slate-500 hover:bg-slate-200/60',
                            !item.clickable ? 'cursor-not-allowed' : '',
                            'group flex gap-x-3 rounded-md px-3 py-2 text-sm leading-6 font-medium',
                          ]"
                          :href="route(item.route, { event: $page.props.event.id, vendor: $page.props.vendor.id })"
                        >
                          <div class="flex items-center gap-3">
                            <span>{{ index + 1 }}.</span>
                            <span>{{ item.name }}</span>
                          </div>
                        </component>
                        <Disclosure v-else v-slot="{ open }" :defaultOpen="true" as="div">
                          <DisclosureButton
                            class="text-slate-500 hover:bg-slate-200/60 hover:text-slate-500 group w-full flex items-center px-3 py-2 text-left text-sm font-medium rounded-md focus:outline-none justify-between"
                          >
                            <span class="flex items-center gap-3">
                              <span>{{ index + 1 }}.</span>
                              <span>{{ item.name }}</span>
                            </span>
                            <ChevronRightIcon
                              :class="[
                                open ? 'rotate-90' : '',
                                'text-slate-500 ml-3 flex-shrink-0 h-5 w-5 transform transition-all duration-200',
                              ]"
                            />
                          </DisclosureButton>
                          <transition
                            enter-active-class="transition duration-200 ease-out"
                            enter-from-class="transform scale-95 opacity-0"
                            enter-to-class="transform scale-200 opacity-200"
                            leave-active-class="transition duration-75 ease-out"
                            leave-from-class="transform scale-200 opacity-200"
                            leave-to-class="transform scale-95 opacity-0"
                          >
                            <DisclosurePanel class="py-1 space-y-1">
                              <component
                                :is="!item.clickable ? 'div' : Link"
                                v-for="item in item.children"
                                :key="item.name"
                                :class="[
                                  isActive(item)
                                    ? 'bg-slate-200/60 text-slate-900'
                                    : 'text-slate-500 hover:bg-slate-200/60',
                                  !item.clickable ? 'cursor-not-allowed' : '',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium pl-14',
                                ]"
                                :href="route(item.route, [props.event, props.vendor, item])"
                              >
                                {{ item.name }}
                              </component>
                              <!-- <Link
                                v-for="item in item.children"
                                :key="item.name"
                                :as="button"
                                :class="[
                                  isActive(item)
                                    ? 'bg-slate-200/60 text-slate-900'
                                    : 'text-slate-500 hover:bg-slate-200/60',
                                  !item.clickable ? 'cursor-not-allowed' : '',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium pl-14',
                                ]"
                                :href="route(item.route, [props.event, props.vendor, item])"
                              >
                                {{ item.name }}
                              </Link> -->
                            </DisclosurePanel>
                          </transition>
                        </Disclosure>
                      </li>
                    </template>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <main class="flex-grow">
          <div class="px-4 sm:px-6 lg:px-8">
            <slot />
          </div>
        </main>
      </div>
    </div>
  </PPage>
</template>
